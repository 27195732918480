import React from "react";
import { RoutePath, AdminRoutePath } from "./path";

const EmployerRegistrationPage = React.lazy(() =>
  import("../../pages/EmployerRegistrationPage")
);
const JobseekerRegistrationPage = React.lazy(() =>
  import("../../pages/JobseekerRegistrationPage")
);
const LoginPage = React.lazy(() =>
  import("../../pages/LoginPage")
);
const ForgotPassword = React.lazy(() =>
  import("../../pages/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("../../pages/ResetPassword")
);
const UserVerify = React.lazy(() =>
  import("../../pages/UserVerify")
);

/*=========-------Admin Path--------=========*/
const AdminLoginPage = React.lazy(() =>
  import("../../pages/AdminLoginPage")
);

const publicRoutes = [
  { path: RoutePath.EMPLOYER_REGISTRATION, name: "Employer Registration | Recruit Graduates", component: EmployerRegistrationPage },
  { path: RoutePath.JOBSEEKER_REGISTRATION, name: "Jobseeker Registration | Recruit Graduates", component: JobseekerRegistrationPage },
  { path: RoutePath.LOGIN, name: "Login | Recruit Graduates", component: LoginPage },
  { path: RoutePath.FORGOT_PASSWORD, name: "Forgot Password | Recruit Graduates", component: ForgotPassword },
  { path: RoutePath.RESET_PASSWORD, name: "Reset Password | Recruit Graduates", component: ResetPassword },
  { path: RoutePath.VERIFY_USER, name: "User Verify | Recruit Graduates", component: UserVerify },

  { path: AdminRoutePath.LOGIN, name: "Admin Login | Recruit Graduates", component: AdminLoginPage }
];

export default publicRoutes;
