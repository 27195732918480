export const RoutePath = {
  INDEX: "/",
  MAININDEX: "/index",
  PUBLIC_POSITIONS: "/jobs/:pageType",
  PUBLIC_APPLICANTS: "/jobseekers/:pageType",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITIONS: "/terms-condition"
};
