export const RoutePath = {
  EMPLOYER_REGISTRATION: "/employer/registration",
  JOBSEEKER_REGISTRATION: "/jobseeker/registration",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  VERIFY_USER: "/verify/:userId"
};

export const AdminRoutePath = {
  LOGIN: "/admin/login"
};