const initialState = {
    applicant: {}
};

const applicantReducer = (state = initialState ,action) => {
    switch(action.type) {

        case 'SET_APPLICANT' : 
        return {...state.applicant,...action.payload}
        case 'UNSET_APPLICANT' :
        return state.applicant = {}
        default :
        return state;
    }

}

export default applicantReducer;