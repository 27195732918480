import React, { useEffect } from "react";
import { useHistory, Route, Redirect, Link } from "react-router-dom";
import { BackgroundStyle, ExploreBoxStyle } from "../utils/defaultMentionStyle";

import "../assets/public/css/all.min.css";
import "../assets/public/css/custom.css";
import "../assets/public/css/own.custom.css";
import "../assets/public/css/responsive.css";
import "../assets/public/js/custom.js";

const Header = React.lazy(() => import("../containers/webLayout/Header"));
const Footer = React.lazy(() => import("../containers/webLayout/Footer"));

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="front-site">
        <Header></Header>
        <div className="portal-login portal-404 d-flex align-items-center" style={BackgroundStyle.img3}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card portal-login-card text-center">
                  <div className="card-body">
                    <div className="card-body-inr">
                      <div className="portal-login-info">
                        <h3>
                          <i className="fal fa-ban"></i><br />404
                        </h3>
                        <p>The page you are looking for <br />could not be found.</p>
                      </div>
                    </div>

                  </div>
                  <div className="card-footer">
                    <Link to="/">Back to home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default NotFoundPage;
