export const RoutePath = {
  HOME: "/account/dashboard",
  PROFILE: "/account/profile",
  EDIT_PROFILE: "/account/edit-profile",
  CV_APPROVEL: "/account/cv/approvel",
  SETTING: "/account/settings",
  POSITIONS: "/account/jobs/search",
  FAVOURITE_POSITIONS: "/account/favourite-jobs",
  MATCHED_POSITIONS: "/account/matched-jobs",
  MANAGE_APPLICATIONS: "/account/applications/manage",
  APPLICANTS: "/account/jobseekers/search",
  UPLOAD_A_POSITION: "/account/job/upload",
  POSITION_EDIT: "/account/job/edit/:pid",
  MANAGE_POSITIONS: "/account/jobs/manage",
  POSITION_DETAILS: "/account/job/detail/:pid/:tab",
  APPLICANT_DETAILS: "/account/jobseeker/detail/:uid/:pageType",
  PENDING_PAGE: "/account/pending",
  DEACTIVATED_PAGE: "/account/deactivated",
};

export const AdminRoutePath = {
  HOME: "/admin/account/dashboard",
  PROFILE: "/admin/account/profile",
  EDIT_PROFILE: "/admin/account/edit-profile",
  SETTING: "/admin/account/settings",
  MANAGE_EMPLOYERS: "/admin/account/employers/manage",
  MANAGE_APPLICANTS: "/admin/account/applicants/manage",
  MANAGE_POSITIONS: "/admin/account/jobs/manage",
  EMPLOYER_DETAILS: "/admin/account/employer/detail/:uid/:pageType",
  EMPLOYER_EDIT: "/admin/account/employer/edit/:uid",
  APPLICANT_DETAILS: "/admin/account/jobseeker/detail/:uid/:pageType",
  APPLICANT_EDIT: "/admin/account/jobseeker/edit/:uid",
  POSITION_DETAILS: "/admin/account/job/detail/:pid/:tab",
  POSITION_EDIT: "/admin/account/job/edit/:pid",
  MANAGE_INSTITUTES: "/admin/account/institutes/manage",
  MANAGE_QUALIFICATIONS: "/admin/account/qualifications/manage",
  MANAGE_JOB_INDUSTRIES: "/admin/account/job-industries/manage",
  MANAGE_JOB_SKILLS: "/admin/account/skills/manage",
  MANAGE_COUNTRIES: "/admin/account/countries/manage",
  MANAGE_PROVINCE: "/admin/account/province/manage",
  MANAGE_CITIES: "/admin/account/cities/manage",
  MANAGE_EMAIL_TEMPLATES: "/admin/account/email-templates/manage",
};
